@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css?family=Mansalva&display=swap);
@import url(https://fonts.googleapis.com/css?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Krona+One&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Mansalva', cursive;
  font-family: 'Cormorant Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  color: #fff;
}



.welcome {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;
  width: 100vw;
  font-size: 1.2em;
}

.actionButton-wrapper {
  height: 50vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.actionButton {
  min-width: 15%;
  width: 140px;
  min-height: 15%;
  margin: auto;
  background: none;
  color: #fff;
  border-radius: 15px;
  border: 2px solid #fff;
  font-size: 1.2em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-family: 'Mansalva', cursive;
}

.actionButton:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
}

.slide-in-left {
	-webkit-animation: slide-in-left 1.5s ease-in-out ;
	        animation: slide-in-left 1.5s ease-in-out ;
} 

.react-rotating-text-cursor {
  -webkit-animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
          animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



.content {
}
.navigation {
  top: 0;
  width: 100vw;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

.logo{
  margin-left: 3%;
  font-size: 1.2rem;
}

nav {
  display: block;
  position: fixed;
  width: 600px;
  height: 600px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translate3d(25px, -25px, 0);
          transform: translate3d(25px, -25px, 0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  transition: -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  transition: transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  transition: transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9), -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
}
nav.open {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 0;
}
nav.top-right {
  top: -252px;
  right: -240px;
}

.disc {
  position: absolute;
  z-index: 1000 !important;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.2rem;
  line-height: 40px;
  padding-top: 10px;
  border-radius: 300px;
  -webkit-transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 1, 180deg);
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 0.6s, -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  transition: opacity 0.6s, -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  transition: transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9), opacity 0.6s;
  transition: transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9), opacity 0.6s, -webkit-transform 0.6s cubic-bezier(0.3, 1.4, 0.5, 0.9);
}
.disc div {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.open .disc {
  pointer-events: auto;
  opacity: 1;
}

.l1 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l1 {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l1:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l1:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l1.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.l2 {
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.open .l2 {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l2:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l2:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l2.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.l3 {
  top: 100px;
  left: 100px;
  right: 100px;
  bottom: 100px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.open .l3 {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l3:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l3:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l3.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.l4 {
  top: 150px;
  left: 150px;
  right: 150px;
  bottom: 150px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}
.open .l4 {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l4:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l4:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l4.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.l5 {
  top: 200px;
  left: 200px;
  right: 200px;
  bottom: 200px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.open .l5 {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l5:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l5:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l5.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.l6 {
  top: 250px;
  left: 250px;
  right: 250px;
  bottom: 250px;
  background: white;
  color: #000;
  text-decoration: none;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.open .l6 {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
          transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 180deg);
  opacity: 1;
}
.open .l6:hover {
  background: shade(white, 90%);
  color: #000;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .l6:active {
  background: shade(white, 50%);
  color: #000;
}
.open .l6.toggle {
  -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
          transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.toggle {
  line-height: 100px;
  padding: 0;
  width: 100px;
  background: none;
  color: #fff;
  border: none;
  outline: none;
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  pointer-events: auto;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.open .toggle {
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
}
.toggle:hover {
  background: shade(#000, 90%);
}
.toggle:active {
  background: shade(#000, 50%);
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
}


@media screen and (max-width: 320px) {
  nav {
    display: none;
  }
}
/*# sourceMappingURL=NavMenu.css.map */

.heroContent {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.contentText {
  max-width: 40vw;
  min-height: 50vh;
  height: 100%;
  min-width: 310px;
  min-height: 200px;
}

.contrastingText {
  background: #fff;
  color: #000;
}

.gifWithFace {
  border: 2px solid white;
  max-width: 320px;
  max-height: 320px;
  min-width: 310px;
  min-height: 200px;
}

.gifWithFace img {
  width: 100%;
  height: 100%;
}

.heroTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.heroDescription {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.heroDescription span {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .heroContent {
    width: 100%;
  }
}
.aboutContent {
  height: 100vh;
  width: 100vw;
  min-height: 100%;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.aboutContentText {
  max-width: 40vw;
  max-height: 50vh;
  min-width: 310px;
  min-height: 200px;
  margin-right: 5%;
}

.pagePrettier {
  border: 2px solid white;
  max-width: 320px;
  max-height: 320px;
  min-width: 310px;
  min-height: 200px;
}

.pagePrettier img {
  height: 100%;
  width: 100%;
}

.aboutTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
  text-align: right;
}

.aboutDescription {
  font-size: 1.2em;
}

.heartEmoji {
  z-index: 500;
}

@media screen and (max-width: 768px) {
  .aboutContent {
    height: 100%;
  }

  .aboutContentText {
    max-width: 40vw;
    max-height: 100%;
    min-width: 290px;
    min-height: 200px;
  }
}


.skills {
  min-height: 100%;
  height: 100%;
  width: 100vw;
}

.skillsContent {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.skillsText {
  max-width: 40vw;
  min-width: 290px;
  min-height: 200px;
}

.skillsTitle>h1 {
  margin-top: 0;
}

.skillsTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.skillsDescription {
  font-size: 1.2em;
}

.formContainer {
  border: 1px solid white;
  max-width: 450px;
  max-height: 450px;
  width: 40vw;
  height: 50vh;
  min-width: 310px;
  min-height: 310px;
}

@media screen and (max-width: 768px) {
  .skills {
    padding-top: 50px;
  }
  .skillsContent {
    height: 100%
  }

  .skillsText{
    width: 290px;
  }
}

.formExample {
  padding: 5% 10%;
  width: 80%;
  height: 100%;
  display: block;
  font-family: 'Krona One', sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s
}

.successLogin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Krona One', sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s
}

#goGreen {
  background: green;
}

.formSection {
  display: flex;
  justify-items: space-evenly;
  flex-direction: column;
  margin-bottom: 20px;
}

.label {
  padding-left: 10px;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.input {
  width: 90%;
  margin: auto;
  height: 36px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  background: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.input:hover {
  background: #fff;
  color: #000;
}

.input:focus {
  background: #fff;
  color: #000;
}

.shake {
  -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  border: 1px solid red;
}

.formActions {
  text-align: center;
}

.goBackButton {
  font-family: 'Krona One', sans-serif;
  width: 90%;
  height: 36px;
  border-radius: 5px;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.goBackButton:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
}

.goBackButton:focus {
  cursor: pointer;
  background: #fff;
  color: #000;
}


.button {
  font-family: 'Krona One', sans-serif;
  margin-top: 21px;
  width: 90%;
  height: 36px;
  border-radius: 5px;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
}

.button:focus {
  cursor: pointer;
  background: #fff;
  color: #000;
}

.buttonDisabled {
  font-family: 'Krona One', sans-serif;
  margin-top: 21px;
  width: 90%;
  height: 36px;
  border-radius: 5px;
  background: grey;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.error {
  text-align: center;  
  font-family: 'Roboto', sans-serif;
  color: red;
  margin: 0 5%;
}

.my-node-enter {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
.my-node-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 800ms;
  transition: -webkit-transform 800ms;
  transition: transform 800ms;
  transition: transform 800ms, -webkit-transform 800ms;
}
.my-node-exit {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.my-node-exit-active {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: -webkit-transform 800ms;
  transition: -webkit-transform 800ms;
  transition: transform 800ms;
  transition: transform 800ms, -webkit-transform 800ms;
}

.back-enter {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.back-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 800ms;
  transition: -webkit-transform 800ms;
  transition: transform 800ms;
  transition: transform 800ms, -webkit-transform 800ms;
}

.back-exit-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.back-exit-active {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: -webkit-transform 800ms;
  transition: -webkit-transform 800ms;
  transition: transform 800ms;
  transition: transform 800ms, -webkit-transform 800ms;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@media screen and (max-width: 768px) {
  .skillsText {
    margin-bottom: 30px;
  }
}



.projectsContent {
  width: 100vw;
  min-height: 100%;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.projectsContentText {
  max-width: 100vw;
  height: 50vh;
  min-width: 90%;
  margin: 0 5%;
}

.projectsTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
  text-align: right;
}

.projectsDescription {
  font-size: 1.2em;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .projectsContent {
    height: 100%;
  }
}

@media screen and (max-width: 320px) {
  .projectsContentText {
    height: 55vh;
  }
}
.projectsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.cardWrapper {
  width: 50%;
}
.projectCard {
  max-width: 320px;
  max-height: 386px;
  margin: auto;
  margin-bottom: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}


.projectImage {
  height: 310px;
  border: 4px solid white;
  position: relative;
}
.projectDemo {
  height: 60px;
  border: 4px solid white;
  color: #000;
  background: #fff;
  font-family: 'Mansalva', cursive;
  text-align: center;
  text-decoration: none;
}

.projectDemo a {
  text-decoration: none;
  color: #000;
}

.projectDemo a:hover {
  cursor: pointer;
}

.projectImage img {
  max-width: 312px;
}

.imgOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.links {
  display: flex;
  width: 90%;
  height: 10%;
  margin: 5% auto;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8em;


}

.links a {
  text-decoration: none;
  color: rgb(255, 251, 0);
  font-family: 'Krona One';
  opacity: 1 !important;
}

.demoLink {
  color: #000;
  background: rgb(255, 251, 0);
}

.projectDescription {
  width: 90%;
  height: 40%;
  margin: auto;
  font-size: 1.2em;
}

.technology {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  height: 40%;
  width: 90%;
  margin: auto;
  font-family: 'Krona One';
  font-size: 0.7em;
}

.projectImage:hover .imgOverlay {
  opacity: 0.8;
}


.contact {
  max-height: 100%;
  min-height: 100vh;
  width: 100vw;
}

.contactContent {
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.contactText {
  min-width: 290px;
  min-height: 200px;
  margin: 0 5%;
}

.contactTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.contactMainText {
  font-size: 1.2em;
}

.contactImage {
  border: 1px solid white;
  max-width: 320px;
  max-height: 320px;
  min-width: 310px;
  min-height: 310px;
}

.contactImage img {
  width: 100%;
  height: 100%;
}

.contactLinks {
  width: 100%;
  margin: 0 5%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap row;
  align-items: center;
}

.social {
  width: 250px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .contactLinks {
    flex-direction: column;

  }
}
