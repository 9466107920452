@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mansalva&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: 'Mansalva', cursive;
  font-family: 'Cormorant Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
