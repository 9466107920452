.contact {
  max-height: 100%;
  min-height: 100vh;
  width: 100vw;
}

.contactContent {
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.contactText {
  min-width: 290px;
  min-height: 200px;
  margin: 0 5%;
}

.contactTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.contactMainText {
  font-size: 1.2em;
}

.contactImage {
  border: 1px solid white;
  max-width: 320px;
  max-height: 320px;
  min-width: 310px;
  min-height: 310px;
}

.contactImage img {
  width: 100%;
  height: 100%;
}

.contactLinks {
  width: 100%;
  margin: 0 5%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap row;
  align-items: center;
}

.social {
  width: 250px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .contactLinks {
    flex-direction: column;

  }
}