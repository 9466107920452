.skills {
  min-height: 100%;
  height: 100%;
  width: 100vw;
}

.skillsContent {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.skillsText {
  max-width: 40vw;
  min-width: 290px;
  min-height: 200px;
}

.skillsTitle>h1 {
  margin-top: 0;
}

.skillsTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.skillsDescription {
  font-size: 1.2em;
}

.formContainer {
  border: 1px solid white;
  max-width: 450px;
  max-height: 450px;
  width: 40vw;
  height: 50vh;
  min-width: 310px;
  min-height: 310px;
}

@media screen and (max-width: 768px) {
  .skills {
    padding-top: 50px;
  }
  .skillsContent {
    height: 100%
  }

  .skillsText{
    width: 290px;
  }
}
