.navigation {
  top: 0;
  width: 100vw;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

.logo{
  margin-left: 3%;
  font-size: 1.2rem;
}