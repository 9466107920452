.projectCard {
  max-width: 320px;
  max-height: 386px;
  margin: auto;
  margin-bottom: 10px;
  transition: 0.5s;
}


.projectImage {
  height: 310px;
  border: 4px solid white;
  position: relative;
}
.projectDemo {
  height: 60px;
  border: 4px solid white;
  color: #000;
  background: #fff;
  font-family: 'Mansalva', cursive;
  text-align: center;
  text-decoration: none;
}

.projectDemo a {
  text-decoration: none;
  color: #000;
}

.projectDemo a:hover {
  cursor: pointer;
}

.projectImage img {
  max-width: 312px;
}

.imgOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  color: #fff;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.links {
  display: flex;
  width: 90%;
  height: 10%;
  margin: 5% auto;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8em;


}

.links a {
  text-decoration: none;
  color: rgb(255, 251, 0);
  font-family: 'Krona One';
  opacity: 1 !important;
}

.demoLink {
  color: #000;
  background: rgb(255, 251, 0);
}

.projectDescription {
  width: 90%;
  height: 40%;
  margin: auto;
  font-size: 1.2em;
}

.technology {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  height: 40%;
  width: 90%;
  margin: auto;
  font-family: 'Krona One';
  font-size: 0.7em;
}

.projectImage:hover .imgOverlay {
  opacity: 0.8;
}

