@import url('https://fonts.googleapis.com/css?family=Krona+One&display=swap');

.heroContent {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.contentText {
  max-width: 40vw;
  min-height: 50vh;
  height: 100%;
  min-width: 310px;
  min-height: 200px;
}

.contrastingText {
  background: #fff;
  color: #000;
}

.gifWithFace {
  border: 2px solid white;
  max-width: 320px;
  max-height: 320px;
  min-width: 310px;
  min-height: 200px;
}

.gifWithFace img {
  width: 100%;
  height: 100%;
}

.heroTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
}

.heroDescription {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.heroDescription span {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .heroContent {
    width: 100%;
  }
}