@import url('https://fonts.googleapis.com/css?family=Krona+One&display=swap');


.projectsContent {
  width: 100vw;
  min-height: 100%;
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.projectsContentText {
  max-width: 100vw;
  height: 50vh;
  min-width: 90%;
  margin: 0 5%;
}

.projectsTitle {
  font-family: 'Krona One', sans-serif;
  font-size: 2em;
  text-align: right;
}

.projectsDescription {
  font-size: 1.2em;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .projectsContent {
    height: 100%;
  }
}

@media screen and (max-width: 320px) {
  .projectsContentText {
    height: 55vh;
  }
}